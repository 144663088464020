/** @format */

import "@components/ripple-button";
import Spinner from "@components/spinner/Loading-spinner";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "antd/dist/antd.min.css";
import "./assets/scss/custom/ripple-button.scss";
import "./assets/scss/fonts/feather/iconfont.css";
import "./assets/scss/react/libs/toastify/toastify.scss";
import "./assets/scss/core.scss";
import "./assets/scss/style.scss";
import { store } from "./redux/storeConfig/store";
import reportWebVitals from "./reportWebVitals";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { ThemeContext } from "./utility/context/ThemeColors";
const LazyApp = lazy(() => import("./App"));

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
          <IntlProviderWrapper>
            <LazyApp />
            <ToastContainer newestOnTop />
          </IntlProviderWrapper>
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
