/** @format */

// **  Initial State
const initialState = {
  userData: {},
  token: '',
  logginIn: false,
  user: {},
  roles: [],
  permissions: [],
  settings: {},
  fullScreen: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        token: action.token,
        userData: action.userData,
      };
    case 'LOGOUT':
      return { ...state, userData: {}, token: '', logginIn: false };
    case 'ADD_USER_DATA':
      localStorage.setItem('userData', JSON.stringify(action.userData));
      return {
        ...state,
        userData: action.userData,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SET_ROLES':
      return {
        ...state,
        roles: action.roles,
      };
    case 'SET_PERIMISSIONS':
      return {
        ...state,
        permissions: action.permissions,
      };
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.settings,
      };
    case 'TOGGLE_FULL_SCREEN':
      return {
        ...state,
        fullScreen: !state.fullScreen,
      };
    default:
      return state;
  }
};

export default authReducer;
