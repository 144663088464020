/** @format */

// ** Initial State
const initialState = {
  start: {},
  end: {},
  categories: {},
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_START":
      return { ...state, start: action.start };
    case "SET_END":
      return { ...state, end: action.end };
    case "SET_CATEGORIES":
      return { ...state, categories: action.categories };
    default:
      return state;
  }
};

export default mapReducer;
